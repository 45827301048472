import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import ProtectedRoute from "./components/ProtectedRoute";
import { AuthContextProvider } from "./context/AuthContext";
import DeviceProvider from "./context/DeviceContext";
import Forgot from "./pages/Auth/Forgot";
import Login from "./pages/Auth/Login";
import Devices from "./pages/Devices";
import History from "./pages/History";
import { Header } from "./shared/components/Header";
import { Sidebar } from "./components/Sidebar";
import Device from "./pages/Device";
import HistoryProvider from "./context/HistoryContext";
import Footer from "./components/Footer";
export interface IAppProps {}
const App: React.FunctionComponent = () => {
  return (
    <AuthContextProvider>
      <HistoryProvider>
        <DeviceProvider>
          <BrowserRouter>
            <Header />
            <section className="flex max-h-screen">
              <Sidebar />
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/reset-password" element={<Forgot />} />
                <Route
                  path="devices"
                  element={
                    <ProtectedRoute>
                      <Devices />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="devices/:deviceId"
                  element={
                    <ProtectedRoute>
                      <Device />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/history"
                  element={
                    <ProtectedRoute>
                      <History />
                    </ProtectedRoute>
                  }
                />
              </Routes>
              <Footer />
            </section>
          </BrowserRouter>
        </DeviceProvider>
      </HistoryProvider>
    </AuthContextProvider>
  );
};

export default App;
