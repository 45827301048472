import React, { useContext, useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import Loader from "../shared/components/Loader";
import SearchBar from "../components/SearchBar";
import { Link } from "react-router-dom";
import { IDevice, DeviceReportData } from "../interfaces/device";
import { DeviceContext } from "../context/DeviceContext";
import { returnDate } from "../shared/utils/utils";
import { CSVLink } from "react-csv";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";
import { sortByParam } from "../shared/utils/utils";
const Devices: React.FunctionComponent = () => {
  const { searchResults, search, getDevices, setSearch } =
    useContext(DeviceContext);
  const { user } = UserAuth();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [reportData, setReportData] = useState<DeviceReportData[]>([]);
  const [sorting, setSorting] = useState<{
    key: string;
    ascending: boolean | null;
  }>({ key: "", ascending: null });
  const reportName = `Device List Report for ${returnDate(
    new Date().getTime(),
    false,
    true
  )}`;
  const headers = [
    { label: "Model ID", key: "id" },
    { label: "Serial Number", key: "sn" },
    { label: "Activation Date", key: "activation_date" },
    { label: "Last Reported Date", key: "last_date" },
    { label: "Channel", key: "apk_channel" },
    { label: "Lock Status", key: "lock_status" },
  ];

  useEffect(() => {
    getDevices();
    setSearch("");
    // eslint-disable-next-line
  }, []);
  function applySorting(key: string) {
    let asc;
    if (key === sorting.key) {
      asc = !sorting.ascending;
    } else {
      asc = true;
    }
    setSorting({ key: key, ascending: asc });
  }
  useEffect(() => {
    setDevices(searchResults);
  }, [searchResults]);
  useEffect(() => {
    if (sorting.key !== "") {
      const devicesCopy = [...devices];
      const sortedDevices = sortByParam(
        sorting.key,
        devicesCopy,
        sorting.ascending!
      );
      setDevices(sortedDevices);
    }

    // eslint-disable-next-line
  }, [sorting]);
  useEffect(() => {
    const searchParams = window.localStorage.getItem("searchParam");
    if (searchParams && searchParams.indexOf("ascending") > -1) {
      const searchParam = JSON.parse(searchParams).param;
      const ascending = JSON.parse(searchParams).ascending;
      setSorting({ key: searchParam, ascending: ascending });
    }
    getDevices();
    setSearch("");
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (devices.length > 0) {
      const downloadData = devices.map((item) => {
        return {
          id: item.id,
          sn: item.sn,
          activation_date: item.first_run
            ? returnDate(item.first_run.seconds)
            : "",
          last_date: item.update_checked
            ? returnDate(item.update_checked?.seconds)
            : "",
          apk_channel: item.apk_channel,
          lock_status: item.cs_lock ? "Locked" : "Open",
        };
      });
      setReportData(downloadData);
    }
  }, [devices]);

  return (
    <section className="main">
      <SearchBar />
      {!user ? (
        <div></div>
      ) : (
        <>
          <h1 className="page-title">
            Device List {devices.length > 0 ? `(${devices.length})` : ""}
          </h1>
          {devices.length === 0 && !search && (
            <div className="spinner-container">
              <Loader />
            </div>
          )}
          {devices.length === 0 && search && (
            <div className="text-2xl mt-5">No devices found</div>
          )}
          {devices.length > 0 && (
            <div className="device-table box-design">
              <div className="table-header flex justify-between">
                <span className="device-cell flex align-items-center">
                  Model ID
                </span>
                <span
                  className="device-cell flex align-items-center cursor-pointer"
                  onClick={() => {
                    applySorting("sn");
                  }}
                >
                  Serial Number{" "}
                  {sorting.key !== "sn" ? (
                    <FaSort className="ml-1" />
                  ) : sorting.ascending ? (
                    <FaSortUp className="ml-1" />
                  ) : (
                    <FaSortDown className="ml-1" />
                  )}
                </span>
                <span
                  className="device-cell flex align-items-center cursor-pointer"
                  onClick={() => {
                    applySorting("first_run");
                  }}
                >
                  Activation Date{" "}
                  {sorting.key !== "first_run" ? (
                    <FaSort className="ml-1" />
                  ) : sorting.ascending ? (
                    <FaSortUp className="ml-1" />
                  ) : (
                    <FaSortDown className="ml-1" />
                  )}
                </span>
                <span
                  className="device-cell flex align-items-center cursor-pointer"
                  onClick={() => {
                    applySorting("update_checked");
                  }}
                >
                  Last Reported Date{" "}
                  {sorting.key !== "update_checked" ? (
                    <FaSort className="ml-1" />
                  ) : sorting.ascending ? (
                    <FaSortUp className="ml-1" />
                  ) : (
                    <FaSortDown className="ml-1" />
                  )}
                </span>
                <span
                  className="device-cell flex align-items-center cursor-pointer"
                  onClick={() => {
                    applySorting("apk_channel");
                  }}
                >
                  Channel{" "}
                  {sorting.key !== "apk_channel" ? (
                    <FaSort className="ml-1" />
                  ) : sorting.ascending ? (
                    <FaSortUp className="ml-1" />
                  ) : (
                    <FaSortDown className="ml-1" />
                  )}
                </span>
                <span
                  className="device-cell flex align-items-center cursor-pointer"
                  onClick={() => {
                    applySorting("cs_lock");
                  }}
                >
                  Lock Status{" "}
                  {sorting.key !== "cs_lock" ? (
                    <FaSort className="ml-1" />
                  ) : sorting.ascending ? (
                    <FaSortUp className="ml-1" />
                  ) : (
                    <FaSortDown className="ml-1" />
                  )}
                </span>
              </div>
              <div className="scroller">
                {devices.map((device) => {
                  return (
                    <Link
                      to={`/devices/${device.id}`}
                      className="flex justify-between text-left device-row"
                      key={device.id}
                    >
                      <span className="device-cell"> {device.id}</span>
                      <span className="device-cell">{device.sn}</span>
                      <span className="device-cell">
                        {device.first_run && device.first_run.seconds > 0
                          ? returnDate(device.first_run.seconds)
                          : ""}
                      </span>
                      <span className="device-cell">
                        {device.update_checked
                          ? returnDate(device.update_checked.seconds)
                          : ""}
                      </span>
                      <span className="device-cell">
                        {device.apk_channel ? device.apk_channel : "Main"}
                      </span>
                      <span className="device-cell flex align-items-center">
                        {device.cs_lock ? "locked" : "open"}
                        <span
                          className={`indicator ml-2 ${
                            device.cs_lock ? "locked" : "open"
                          }`}
                        ></span>
                      </span>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {devices.length > 0 && (
            <CSVLink
              data={reportData}
              headers={headers}
              filename={reportName}
              className="mt-2 inline-block"
            >
              Download Report
            </CSVLink>
          )}
        </>
      )}
    </section>
  );
};

export default Devices;
