import { useContext, useEffect, useState } from "react";
import { HistoryContext } from "../context/HistoryContext";
import { returnDate } from "../shared/utils/utils";
import Loader from "../shared/components/Loader";
import { HistoryReportData, IHistory } from "../interfaces/history";
import { FaSortDown, FaSort } from "react-icons/fa";
import { CSVLink } from "react-csv";
const History: React.FunctionComponent = () => {
  const { getHistory } = useContext(HistoryContext);
  const [history, setHistory] = useState<IHistory[] | null>(null);
  const [sorting, setSorting] = useState("date");
  const [reportData, setReportData] = useState<HistoryReportData[]>([]);
  const reportName = `Activity Log Report for ${returnDate(
    new Date().getTime(),
    false,
    true
  )}`;
  const headers = [
    { label: "Action by", key: "actionBy" },
    { label: "Model ID", key: "id" },
    { label: "Action", key: "action" },
    { label: "Previous", key: "prev" },
    { label: "Changed to", key: "curr" },
    { label: "Timestamp", key: "time" },
  ];

  const getHistoryFunc = async () => {
    const data = await getHistory();
    const sorted = sortData(data, "date");

    setHistory(sorted);
  };

  useEffect(() => {
    getHistoryFunc();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (history && history.length > 0) {
      const downloadData = history.map((item) => {
        return {
          actionBy: item.user,
          id: item.modelId,
          action: item.action.type,
          prev: item.action.prev,
          curr: item.action.curr,
          time: returnDate(item.dateAndTime?.seconds),
        };
      });
      setReportData(downloadData);
    }
  }, [history]);

  const sortData = (data: IHistory[], sortParam: "id" | "date") => {
    let sorted;
    if (sortParam === "date") {
      sorted = data.sort(
        (a: IHistory, b: IHistory) =>
          b!.dateAndTime?.seconds - a!.dateAndTime?.seconds
      );
    } else {
      sorted = data.sort((a: IHistory, b: IHistory) => {
        return a!.modelId!.localeCompare(b!.modelId!, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    }
    return sorted;
  };
  const setHistoryOrder = (sortParam: "id" | "date") => {
    const sorted = sortData(history!, sortParam);
    setSorting(sortParam);
    setHistory([...sorted]);
  };
  return (
    <>
      <section className="main">
        <h1 className="page-title">Activity Log</h1>
        <div className="device-table history-table  box-design">
          <div className="table-header flex justify-between">
            <span className="activity-cell">Action by</span>
            <span
              className="activity-cell flex align-items-center cursor-pointer"
              onClick={() => setHistoryOrder("id")}
            >
              Model ID{" "}
              {sorting === "id" ? (
                <FaSortDown className="ml-1" />
              ) : (
                <FaSort className="ml-1" />
              )}
            </span>
            <span className="activity-cell">Action</span>
            <span className="activity-cell">Previous</span>
            <span className="activity-cell">Changed to</span>
            <span
              className="activity-cell flex align-items-center cursor-pointer"
              onClick={() => setHistoryOrder("date")}
            >
              Timestamp{" "}
              {sorting === "date" ? (
                <FaSortDown className="ml-1" />
              ) : (
                <FaSort className="ml-1" />
              )}
            </span>
          </div>

          {!history && (
            <div className="spinner-container">
              <Loader />
            </div>
          )}
          {history && history.length > 0 && (
            <div className="scroller">
              {history &&
                history.map((item) => {
                  return (
                    <div
                      className="flex justify-between text-left device-row"
                      key={item.id}
                    >
                      <span className="activity-cell">{item.user}</span>
                      <span className="activity-cell">{item.modelId}</span>
                      <span className="activity-cell">{item.action?.type}</span>
                      <span className="activity-cell">{item.action?.prev}</span>
                      <span className="activity-cell">{item.action?.curr}</span>
                      <span className="activity-cell">
                        {item.dateAndTime
                          ? returnDate(item.dateAndTime.seconds)
                          : ""}
                      </span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        {history && (
          <CSVLink
            data={reportData}
            headers={headers}
            filename={reportName}
            className="mt-2 inline-block"
          >
            Download Report
          </CSVLink>
        )}
      </section>
    </>
  );
};

export default History;
