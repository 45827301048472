import { createContext, useState, useEffect } from "react";
import { ContextProps } from "../interfaces/interfaces";
import { DevicesContextInterface, IDevice } from "../interfaces/device";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  deleteField,
  FieldValue,
} from "firebase/firestore";
import { db, deviceDB } from "../config/firebase";
import { UserAuth } from "./AuthContext";
import { sortByParam } from "../shared/utils/utils";
export const DeviceContext = createContext<DevicesContextInterface>(
  {} as DevicesContextInterface
);

const DeviceProvider = ({ children }: ContextProps) => {
  const { user } = UserAuth();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<IDevice[]>([]);

  const devicesCollectionRef = collection(db, deviceDB);

  const getDevices = async () => {
    const data = await getDocs(devicesCollectionRef);
    const tempDevices = data.docs.map((doc) => ({
      ...doc.data(),
      sn: doc.data().sn,
      apk_channel: doc.data().apk_channel,
      first_run: doc.data().first_run,
      id: doc.id,
      cs_lock: doc.data().cs_lock ? doc.data().cs_lock : false,
      cs_reset: doc.data().cs_reset ? doc.data().cs_reset : false,
    }));
    const searchParams = window.localStorage.getItem("searchParam");

    if (searchParams && searchParams.indexOf("ascending") > -1) {
      const searchParam = JSON.parse(searchParams).param;
      const ascending = JSON.parse(searchParams).ascending;
      setDevices(sortByParam(searchParam, tempDevices, ascending));
    } else {
      if (searchParams) {
        window.localStorage.removeItem("searchParam");
      }
      setDevices(tempDevices);
    }
  };

  const returnDevice = (id: string) => {
    const selectedDevice = devices.find((device) => device.id === id);
    return selectedDevice;
  };

  const replaceDevice = (deviceToReplace: IDevice) => {
    const newDevices = devices.map((device) => {
      if (device.id === deviceToReplace.id) {
        return deviceToReplace;
      }
      return device;
    });
    setDevices(newDevices);
  };
  const updateDevice = async (
    id: string,
    changes: { apk_channel?: string | FieldValue }
  ) => {
    const deviceDoc = doc(db, deviceDB, id);
    if (changes.apk_channel === "Main") {
      changes.apk_channel = deleteField();
    }
    await updateDoc(deviceDoc, changes);
  };
  useEffect(() => {
    if (user) {
      getDevices();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setSearchResults(devices);
  }, [devices]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredResults = devices.filter(
        (device) =>
          device.id.includes(search) ||
          (device.sn && device.sn.includes(search))
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults(devices);
    }
  }, [search, devices]);

  return (
    <DeviceContext.Provider
      value={{
        setSearch,
        searchResults,
        search,
        returnDevice,
        updateDevice,
        getDevices,
        replaceDevice,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
