import { IDevice } from "../../interfaces/device";

export const showError = (error: string, email?: string) => {
  return error.indexOf("user-not-found") > 0
    ? `Email ${email} does not exist`
    : error.indexOf("wrong-password") > 0
    ? "Incorrect password"
    : error.indexOf("invalid-email") > 0
    ? `${email} is an invalid email`
    : "Unknown Error";
};
export const sortByParam = (
  param: string,
  devices: IDevice[],
  ascending = true
) => {
  window.localStorage.setItem(
    "searchParam",
    JSON.stringify({ param, ascending })
  );

  let sorted;
  if (param === "first_run") {
    sorted = devices.sort((a: IDevice, b: IDevice) => {
      if (!b.first_run) {
        b.first_run = {
          nanoseconds: 0,
          seconds: 0,
        };
      }
      if (!a.first_run) {
        a.first_run = {
          nanoseconds: 0,
          seconds: 0,
        };
      }
      if (!ascending) {
        return b!.first_run!.seconds - a!.first_run?.seconds;
      } else {
        return a!.first_run!.seconds - b!.first_run?.seconds;
      }
    });
  }
  if (param === "update_checked") {
    sorted = devices.sort((a: IDevice, b: IDevice) => {
      if (!ascending) {
        return b!.update_checked!.seconds - a!.update_checked!.seconds;
      } else {
        return a!.update_checked!.seconds - b!.update_checked!.seconds;
      }
    });
  } else if (param === "sn") {
    sorted = devices.sort((a: IDevice, b: IDevice) => {
      if (a.sn && b.sn) {
        if (!ascending) {
          return b!.sn!.localeCompare(a!.sn!, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        } else {
          return a!.sn!.localeCompare(b!.sn!, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }
      } else {
        b.sn = b.sn ? b.sn : "";
        a.sn = a.sn ? a.sn : "";
        if (!ascending) {
          return b!.sn!.localeCompare(a!.sn!, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        } else {
          return a!.sn!.localeCompare(b!.sn!, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }
      }
    });
  } else if (param === "cs_lock") {
    sorted = devices.sort((a: IDevice, b: IDevice) => {
      if (!ascending) {
        return a.cs_lock === b.cs_lock ? 0 : b.cs_lock ? -1 : 1;
      } else {
        return a.cs_lock === b.cs_lock ? 0 : a.cs_lock ? -1 : 1;
      }
    });
  } else if (param === "apk_channel") {
    sorted = devices.sort((a: IDevice, b: IDevice) => {
      if (!a.apk_channel) {
        a.apk_channel = "Main";
      }
      if (!b.apk_channel) {
        b.apk_channel = "Main";
      }

      if (!ascending) {
        return b!.apk_channel!.localeCompare(a!.apk_channel!, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return a!.apk_channel!.localeCompare(b!.apk_channel!, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    });
  } else {
    sorted = devices;
  }
  return [...sorted];
};
export const returnDate = (
  secs: number,
  withTime?: boolean,
  forReport?: boolean
) => {
  const date = forReport ? new Date(secs) : new Date(secs * 1000);
  const addTime =
    String(date.getHours()) + ":" + String(date.getMinutes()).padStart(2, "0");
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");
  if (withTime) {
    return `${formattedDate}  ${addTime}`;
  }
  return formattedDate;
};

export const stringToBoolean = (str: string) => {
  if (str === "false") {
    return false;
  }
  if (str === "true") {
    return true;
  }
};

export const returnNotAvailable = (param: string | boolean | undefined) =>
  param ? param : "N/A";

export const channelOptions = window.location.host.includes(
  "devmgmt.mobileodt.com"
)
  ? ["Main", "Zoom", "Staging", "StagingZoom"]
  : ["Dev", "DevZoom"];
