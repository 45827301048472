import { createContext } from "react";
import { ContextProps } from "../interfaces/interfaces";
import { collection, getDocs, doc, writeBatch } from "firebase/firestore";
import { db, historyDB } from "../config/firebase";
import { HistroyContextInterface, IHistory } from "../interfaces/history";

export const HistoryContext = createContext<HistroyContextInterface>(
  {} as HistroyContextInterface
);
const HistoryProvider = ({ children }: ContextProps) => {
  const historyCollectionRef = collection(db, historyDB);

  const addHistroy = async (items: IHistory[]) => {
    const batch = writeBatch(db);
    await items.forEach((item) => {
      const docRef = doc(collection(db, historyDB));
      batch.set(docRef, item);
    });
    await batch.commit();
  };

  const getHistory = async () => {
    const data = await getDocs(historyCollectionRef);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      user: doc.data().user,
    }));
  };

  return (
    <HistoryContext.Provider
      value={{
        getHistory,
        addHistroy,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
