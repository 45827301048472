import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { Logo } from "../shared/components/Logo";
import { FaHome, FaHistory, FaSignOutAlt } from "react-icons/fa";
export const Sidebar = () => {
  const location = useLocation();
  const { logout } = UserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      const typedError = err as Error;
      console.log(typedError?.message);
    }
  };
  if (location.pathname === "/" || location.pathname === "/reset-password") {
    return <></>;
  }
  return (
    <aside className="sidebar pr-2 pl-4 flex flex-wrap">
      <div className="w-full flex flex-wrap">
        <div className=" w-full text-center flex-col flex">
          <Logo width={100} height={30} className="mt-2" />

          <Link
            to="/devices"
            className={`${
              location.pathname === "/devices" ? "selected" : ""
            } sidebar-icons`}
          >
            <FaHome className="m-auto p-1" size={40} />
            Home
          </Link>

          <Link
            to="/history"
            className={`${
              location.pathname === "/history" ? "selected" : ""
            } sidebar-icons`}
          >
            <FaHistory className="m-auto p-2" size={40} />
            History
          </Link>
          <button
            className="w-full text-center mb-5 sidebar-icons"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="m-auto p-2" size={40} />
            Logout
          </button>
        </div>
      </div>
    </aside>
  );
};
