import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { UserAuth } from "../../context/AuthContext";
import { Error } from "../../shared/components/Error";
import { Link, useNavigate } from "react-router-dom";
import { showError } from "../../shared/utils/utils";
import { FaArrowLeft } from "react-icons/fa";
import { SystemButton } from "../../shared/components/SystemBtn";

const Forgot: React.FunctionComponent = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { resetPassword } = UserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setSubmitting(true);
    e.preventDefault();
    setError("");
    try {
      await resetPassword(email);
      navigate("/");
    } catch (err) {
      const typedError = err as Error;
      const error = showError(typedError?.message, email);
      setError(error);
      setSubmitting(false);
    }
  };
  return (
    <div className="flex justify-center flex-wrap w-full">
      <div className="rounded login-card">
        <h2 className="text-center mb-3 text-xl">Reset Password</h2>
        <Form onSubmit={handleSubmit} className="relative">
          <Form.Group id="email" className="mb-4">
            <Form.Label className="block text-sm text-slate-700 mb-1">
              Email
            </Form.Label>
            <Form.Control
              type="email"
              className="block login-border border p-2 rounded w-full"
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
          </Form.Group>

          <SystemButton disabled={submitting || !email} theme="mainBtn">
            Reset Password
          </SystemButton>
          <Error error={error} className="login-error" />
        </Form>
      </div>
      <Link
        to="/"
        className="w-full text-center flex justify-center mt-2 text-sm link-color hover:underline"
      >
        <div className="backWrapper flex items-center justify-center">
          <FaArrowLeft /> <span className="ml-2">Back to login</span>
        </div>
      </Link>
    </div>
  );
};

export default Forgot;
