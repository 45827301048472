import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Error } from "../../shared/components/Error";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { showError } from "../../shared/utils/utils";
import { SystemButton } from "../../shared/components/SystemBtn";

const Login: React.FunctionComponent = () => {
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { signIn, user } = UserAuth();

  const navigate = useNavigate();
  const toggleView = () => {
    setPasswordVisibility((prevValue) => !prevValue);
  };
  const eyeOnIcon = (
    <svg width="2em" height="2em" viewBox="0 0 24 24">
      <title>show password</title>
      <path
        fill="#8C95A5"
        d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
      />
    </svg>
  );
  const eyeOffIcon = (
    <svg width="2em" height="2em" viewBox="0 0 24 24">
      <title>hide password</title>
      <path
        fill="#8C95A5"
        d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"
      />
    </svg>
  );
  const handleSubmit = async (e: React.SyntheticEvent) => {
    setAuthenticating(true);
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
      navigate("/devices");
    } catch (err) {
      const typedError = err as Error;
      const error = showError(typedError?.message, email);
      setError(error);
      setAuthenticating(false);
    }
  };
  useEffect(() => {
    if (user) {
      navigate("/devices");
    }
  }, [user, navigate]);
  return (
    <div className="flex justify-center flex-wrap w-full">
      <div className="login-card rounded" data-testid="login-card">
        <h2 className="text-center mb-3 text-xl">Login</h2>
        <Form onSubmit={handleSubmit} className="relative">
          <Form.Group id="email" className="mb-4">
            <Form.Label className="block text-sm text-slate-700 mb-1">
              Email
            </Form.Label>
            <div>
              <Form.Control
                type="email"
                className="block login-border border p-2 rounded w-full"
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(event.target.value)
                }
              />
            </div>
          </Form.Group>
          <Form.Group id="password" className="mb-3">
            <Form.Label className="block text-sm text-slate-700 mb-1">
              Password
            </Form.Label>
            <div className="relative">
              <Form.Control
                className="block login-border border p-2 rounded w-full"
                type={isPasswordVisible ? "text" : "password"}
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
              />
              <div
                role="button"
                className="absolute top-1 right-1"
                onClick={toggleView}
                tabIndex={0}
              >
                {isPasswordVisible ? eyeOffIcon : eyeOnIcon}
              </div>
            </div>
          </Form.Group>

          <SystemButton
            disabled={authenticating || !email || !password}
            theme="mainBtn"
          >
            Sign in
          </SystemButton>
          <Error error={error} className="login-error" />
        </Form>
      </div>
      <div className="text-center mt-1 w-full">
        <Link
          to="/reset-password"
          className="text-sm hover:underline link-color"
        >
          Forgot password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
