import { useLocation } from "react-router-dom";
export default function Footer() {
  const location = useLocation();
  const year = new Date().getFullYear().toString();
  return (
    <div
      className={`${
        location.pathname === "/" || location.pathname === "/reset-password"
          ? "text-sm text-center left-1/2 translate-center bottom-5"
          : location.pathname === "/history"
          ? "text-base place-footer place-footer-history"
          : "text-base place-footer  place-footer-devices"
      } text-color absolute `}
    >
      © {year} MobileODT {process.env.REACT_APP_VERSION}
    </div>
  );
}
