import { MouseEventHandler, ReactNode } from "react";

export interface ISystemButtonProps {
  disabled?: boolean;
  className?: string;
  theme?: "mainBtn" | "secondBtn";
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function SystemButton(props: ISystemButtonProps) {
  const { disabled, className, children, onClick, theme } = props;
  return (
    <button
      type="submit"
      className={`${className} ${theme} w-full text-center rounded-md system-button disabled:bg-slate-300 disabled:hover:bg-slate-300 text-white py-2 mt-2 `}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
