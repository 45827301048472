import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import Loader from "../shared/components/Loader";
import { IDevice } from "../interfaces/device";
import { DeviceContext } from "../context/DeviceContext";
import { useParams } from "react-router-dom";
import { LooseObject, TabOptions } from "../interfaces/interfaces";
import {
  returnDate,
  returnNotAvailable,
  showError,
  stringToBoolean,
  channelOptions,
} from "../shared/utils/utils";
import { Form } from "react-bootstrap";
import { SystemButton } from "../shared/components/SystemBtn";
import { Error } from "../shared/components/Error";
import { SystemModal } from "../shared/components/Modal";
import { HistoryContext } from "../context/HistoryContext";
import { Timestamp } from "firebase/firestore";
import { IHistory } from "../interfaces/history";
import { FaChevronRight } from "react-icons/fa";
const Device: React.FunctionComponent = () => {
  const { returnDevice, updateDevice, replaceDevice } =
    useContext(DeviceContext);
  const { user } = UserAuth();
  const { addHistroy } = useContext(HistoryContext);

  const { deviceId } = useParams();
  const [selectedDevice, setSelectedDevice] = useState<IDevice>(
    returnDevice(deviceId!)!
  );
  const [selectedTab, setSelectedTab] = useState<TabOptions>("info");
  const [changes, setChanges] = useState({
    cs_lock: String(selectedDevice?.cs_lock),
    cs_reset: String(selectedDevice?.cs_reset),
    apk_channel: selectedDevice?.apk_channel,
  });
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [disableButton, setDisablebutton] = useState<boolean>(true);

  const modalText =
    "do you want to save the changes and update the user's settings?";

  const setChangesFunction = () => {
    setChanges({
      cs_lock: String(selectedDevice?.cs_lock),
      cs_reset: String(selectedDevice?.cs_reset),
      apk_channel: selectedDevice?.apk_channel,
    });
  };
  useEffect(() => {
    if (deviceId) {
      const device = returnDevice(deviceId);
      setSelectedDevice(device!);
    }
  }, [deviceId, returnDevice]);

  useEffect(() => {
    setChangesFunction();
    // eslint-disable-next-line
  }, [selectedDevice]);

  // const getAndroidVersion = (str: string) => str.split("-")[0];
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    e.persist();
    setDisablebutton(false);
    switch (e.target.id) {
      case "lockOpenDevice":
        setChanges((prevState) => ({
          ...prevState,
          cs_lock: e.target.value,
        }));
        break;
      case "enableDisableReset":
        setChanges((prevState) => ({
          ...prevState,
          cs_reset: e.target.value,
        }));
        break;
      case "channelSelect":
        setChanges((prevState) => ({
          ...prevState,
          apk_channel: e.target.value,
        }));
        break;
    }
  };
  const handleCancel = () => {
    setChangesFunction();
    setDisablebutton(true);
  };
  const handleSubmit = async () => {
    setError("");
    setDisablebutton(true);
    const boolChanges: LooseObject = {};
    const newHistoryArray: IHistory[] = [];
    const changedSelectedDevice = { ...selectedDevice };
    if (selectedDevice.cs_lock !== stringToBoolean(changes.cs_lock)) {
      boolChanges.cs_lock = stringToBoolean(changes.cs_lock);
      changedSelectedDevice.cs_lock = stringToBoolean(changes.cs_lock)!;
      newHistoryArray.push({
        user: user!.email!,
        dateAndTime: Timestamp.fromDate(new Date()),
        action: {
          type: "Locking Status",
          prev: selectedDevice.cs_lock ? "Locked" : "Open",
          curr: boolChanges.cs_lock ? "Locked" : "Open",
        },
        modelId: selectedDevice.id,
      });
    }

    if (selectedDevice.cs_reset !== stringToBoolean(changes.cs_reset)) {
      boolChanges.cs_reset = stringToBoolean(changes.cs_reset);
      changedSelectedDevice.cs_reset = stringToBoolean(changes.cs_reset)!;
      newHistoryArray.push({
        user: user!.email!,
        dateAndTime: Timestamp.fromDate(new Date()),
        action: {
          type: "Factory Reset",
          prev: selectedDevice.cs_reset ? "Enabled" : "Disabled",
          curr: boolChanges.cs_reset ? "Enabled" : "Disabled",
        },
        modelId: selectedDevice.id,
      });
    }
    if (selectedDevice.apk_channel !== changes.apk_channel) {
      boolChanges.apk_channel = changes.apk_channel;
      changedSelectedDevice.apk_channel = changes.apk_channel!;
      newHistoryArray.push({
        user: user!.email!,
        dateAndTime: Timestamp.fromDate(new Date()),
        action: {
          type: "Channel Update",
          prev: selectedDevice.apk_channel
            ? selectedDevice.apk_channel
            : "Main",
          curr: boolChanges.apk_channel ? boolChanges.apk_channel : "Main",
        },
        modelId: selectedDevice.id,
      });
    }
    try {
      await updateDevice(deviceId!, boolChanges);
      await addHistroy(newHistoryArray);

      setSelectedDevice(changedSelectedDevice);
      replaceDevice(changedSelectedDevice);
    } catch (err) {
      const typedError = err as Error;
      const error = showError(typedError?.message);
      setError(error);
    }
  };

  return (
    <section className="main">
      <h1 className="page-title">Device Management Board</h1>
      {!selectedDevice ? (
        <div className="spinner-container">
          <Loader />
        </div>
      ) : (
        <>
          <div className="tabs-table shadow p-3 mt-8">
            <div className="tabs-header p-2 text-xl text-bold text-center mb-3">
              {selectedTab === "info" ? "System Information" : "Work Place"}
            </div>
            <div className="tabs-body flex w-100">
              <div className="tabs-buttons px-3 pb-3 mr-6">
                <div
                  onClick={() => setSelectedTab("info")}
                  className={`${
                    selectedTab === "info" ? "selected" : ""
                  } single-tab px-2 flex py-3 text-left items-center justify-between mb-3`}
                >
                  <span>System Information</span>
                  <FaChevronRight className="selected-icon" />
                </div>
                <div
                  className={`${
                    selectedTab === "actions" ? "selected" : ""
                  } single-tab px-2 flex py-3 text-left items-center justify-between`}
                  onClick={() => setSelectedTab("actions")}
                >
                  <span>Work Place</span>
                  <FaChevronRight className="selected-icon" />
                </div>
              </div>
              {selectedTab === "info" && (
                <div className="tabs-info w-100">
                  <div className="info-right w-50 float-left">
                    <div className=" info-row flex">
                      <div className="cell info-cell">Serial Number</div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.sn)}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">Activation Date</div>
                      <div className="cell info-cell">
                        {selectedDevice.first_run
                          ? returnDate(selectedDevice.first_run.seconds, true)
                          : "N/A"}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">Last Reported Date</div>
                      <div className="cell info-cell">
                        {selectedDevice.update_checked
                          ? returnDate(
                              selectedDevice.update_checked.seconds,
                              true
                            )
                          : "N/A"}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">
                        Total Internal Storage
                      </div>
                      <div className="cell info-cell">
                        {selectedDevice.storage_space}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">
                        Free Internal Storage
                      </div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.usable_space)}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">RAM</div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.RAM)}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">Model ID</div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.id)}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">OS Version</div>
                      <div className="cell info-cell">
                        {selectedDevice.OS ? selectedDevice.OS : "N/A"}
                      </div>
                    </div>
                    <div className=" info-row flex">
                      <div className="cell info-cell">Hardware Version</div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.hardware)}
                      </div>
                    </div>

                    <div className=" info-row flex">
                      <div className="cell info-cell">Local Timezone</div>
                      <div className="cell info-cell">
                        {returnNotAvailable(selectedDevice.timezone)}
                      </div>
                    </div>
                  </div>
                  {selectedDevice.managed_apks &&
                    typeof selectedDevice.managed_apks === "object" &&
                    Object.keys(selectedDevice.managed_apks).length > 0 && (
                      <div className="info-left w-50 py-2 px-3 float-left">
                        <div className="font-bold">Managed APKs</div>
                        <div className="ml-3">
                          {Object.keys(selectedDevice.managed_apks!)
                            .sort()
                            .map((keyName) => {
                              return (
                                <div key={keyName}>
                                  <span className="font-bold">{keyName} </span>{" "}
                                  <span>
                                    {selectedDevice.managed_apks![keyName]}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
              )}
              {selectedTab === "actions" && (
                <div className="tabs-info w-100 flex flex-wrap">
                  <div className="info-wrapper flex">
                    <div className="info-right">
                      <div className=" info-row flex">
                        <div className="cell info-cell">Lock Device</div>
                        <div className="cell info-cell flex align-items-center">
                          <span>
                            {selectedDevice.cs_lock ? "Locked" : "Open"}
                          </span>
                          <span
                            className={`${
                              selectedDevice.cs_lock ? "locked" : "open"
                            } indicator ml-2`}
                          ></span>
                        </div>
                        <div className="cell info-cell">
                          <Form.Group
                            controlId="lockOpenDevice"
                            className="flex"
                          >
                            <Form.Check
                              value="false"
                              type="radio"
                              aria-label="radio 2"
                              className="mr-2"
                              label="Open"
                              onChange={handleChange}
                              checked={changes.cs_lock === "false"}
                            />
                            <Form.Check
                              value="true"
                              type="radio"
                              aria-label="radio 1"
                              label="Lock"
                              onChange={handleChange}
                              checked={changes.cs_lock === "true"}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className=" info-row flex">
                        <div className="cell info-cell">Factory Reset</div>
                        <div className="cell info-cell">
                          {selectedDevice.cs_reset ? "Enabled" : "Disabled"}
                        </div>
                        <div className="cell info-cell">
                          <Form.Group
                            controlId="enableDisableReset"
                            className="flex"
                          >
                            <Form.Check
                              value="true"
                              type="radio"
                              aria-label="radio 1"
                              className="mr-2"
                              label="Enable"
                              onChange={handleChange}
                              checked={changes.cs_reset === "true"}
                            />
                            <Form.Check
                              value="false"
                              type="radio"
                              aria-label="radio 2"
                              label="Disable"
                              onChange={handleChange}
                              checked={changes.cs_reset === "false"}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className=" info-row flex">
                        <div className="cell info-cell">Device Channel</div>
                        <div className="cell info-cell">
                          {selectedDevice.apk_channel
                            ? selectedDevice.apk_channel
                            : "Main"}
                        </div>
                        <div className="cell info-cell">
                          <Form.Select
                            id="channelSelect"
                            value={changes.apk_channel}
                            onChange={handleChange}
                          >
                            {channelOptions.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-end flex-wrap">
                    <SystemButton
                      theme="mainBtn"
                      className="w-3/12 mt-5"
                      disabled={disableButton}
                      onClick={() => setShow(true)}
                    >
                      Apply
                    </SystemButton>
                    <Error error={error} className="login-error" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <SystemModal
        text={modalText}
        okButtonText="Yes"
        cancelButtonText="No - undo changes"
        setShow={setShow}
        show={show}
        confirmFunc={handleSubmit}
        cancelFunc={handleCancel}
      />
    </section>
  );
};
export default Device;
