import React, { useContext } from "react";
import { FaSearch } from "react-icons/fa";
import { DeviceContext } from "../context/DeviceContext";
export interface ISearchProps {}

export default function SearchBar() {
  const { setSearch } = useContext(DeviceContext);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 1) {
      setSearch(event.currentTarget.value);
    } else if (event.currentTarget.value.length === 0) {
      setSearch("");
    }
  };
  return (
    <div className="search-bar box-design">
      <FaSearch size={20} />
      <input
        type="text"
        placeholder="Search for SN or ID"
        onChange={handleOnChange}
      ></input>
    </div>
  );
}
