import Modal from "react-bootstrap/Modal";
import { SystemButton } from "./SystemBtn";

export interface ISystemModalProps {
  text: string;
  okButtonText?: string;
  cancelButtonText?: string;
  show: boolean;
  setShow: (arg: boolean) => void;
  confirmFunc: Function;
  cancelFunc: Function;
}

export function SystemModal(props: ISystemModalProps) {
  const {
    text,
    okButtonText = "OK",
    cancelButtonText = "Cancel",
    setShow,
    show = false,
    confirmFunc,
    cancelFunc,
  } = props;

  const handleClose = () => setShow(false);
  const handleCancel = () => {
    cancelFunc();
    handleClose();
  };
  const confirmAction = () => {
    confirmFunc();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="text-2xl text-center">{text}</Modal.Body>
      <Modal.Footer className="flex flex-nowrap border-0">
        <SystemButton onClick={handleCancel} theme="secondBtn">
          {cancelButtonText}
        </SystemButton>
        <SystemButton onClick={confirmAction} theme="mainBtn">
          {okButtonText}
        </SystemButton>
      </Modal.Footer>
    </Modal>
  );
}
