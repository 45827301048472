import { useLocation } from "react-router-dom";
import { Logo } from "./Logo";

export function Header() {
  const location = useLocation();
  if (location.pathname !== "/" && location.pathname !== "/reset-password") {
    return <></>;
  }
  return (
    <div className="w-full">
      <Logo width={250} height={72} className="mt-4" />
      <div className="text-xl text-slate-600 text-center mt-1">
        Welcome to MobileODT Device Control Center!
      </div>
    </div>
  );
}
