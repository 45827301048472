import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "./config";
import { getFirestore } from "firebase/firestore";

export const firebase = initializeApp(config.firebase);
export const auth = getAuth(firebase);
export const db = getFirestore(firebase);
//todo: add function to set db according to staging or prod
export const deviceDB = window.location.host.includes("devmgmt.mobileodt.com")
  ? "odt-serialdata"
  : "odt-serialdata-staging";

export const historyDB = window.location.host.includes("devmgmt.mobileodt.com")
  ? "cs-action-history"
  : "cs-action-history-staging";
